/* ==========================================================================
   Visual bullets (image + text)
   ========================================================================== */

h1 a.inline,
h2 a.inline {
	font-size: 1.125rem;
	font-weight: normal;
	font-family: $base-font;
	border-bottom: 1px dotted #d5d5f7;
	margin-left: 2em;
}

ul.posts-toc {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	line-height: 2em;
	margin: 0 0 2em;
	padding: 0;

	li {
		margin: 0.5em 1em;
		list-style-type: none;

		a {
			border: thin solid;
			padding: 0.2em 0.5em;
		}

	}
}

ul.posts {
	margin-left: 0;
	padding-left: 0;

	li {
		list-style-type: none;
		margin-bottom: 0.5em;
		margin-top: 1em;
		border-top: none;
		padding-top: 1em;
		padding-bottom: 1em;

		img {
			max-width: 25em;
		}

		>div {
			h2 {
				font-size: 1.5em;
				margin-bottom: 0.2rem;
				margin-top: 1rem;
			}

			time {
				display: inline;
			}

			ul.tags {

				display: inline;

				li {
					display: inline;
					margin-right: 0.5em;
					border-radius: 1em 0.5em 0.5em 1em;
					background-color: darken($link-color, 10);
					padding: 0.2em 0.8em 0.2em 1em;

					&:before {
						content: "•";
						font-size: 2em;
						color: $white;
						position: relative;
						top: 0.35rem;
						left: -0.5rem;
					}

					&:hover {
						text-decoration: none;
						background-color: lighten($link-color, 15);
					}

					a {
						margin-right: 0;
						border: none;
						color: $white;

						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

ul.tags {

	font-family: $alt-font;

	li {
		display: inline;
		margin-right: 0.5em;
		border-radius: 1em 0.5em 0.5em 1em;
		background-color: darken($link-color, 10);
		opacity: 0.75;
		padding: 0.2em 0.8em 0.2em 1em;

		&:before {
			content: "•";
			font-size: 2em;
			color: white;
			position: relative;
			top: 0.35rem;
			left: -0.5rem;
		}

		&:hover {
			text-decoration: none;
			background-color: lighten($link-color, 15);
		}

		a {
			margin-right: 0;
			border: none;
			color: white;
			text-transform: uppercase;

			&:hover {
				text-decoration: none;
				border-bottom: none;
			}
		}
	}

	&.alone {
		margin-left: 0;
		padding-left: 0;
		margin-bottom: 2em;

		&:before {
			content: "Štítky: ";
			margin-right: 1em;
		}
	}
}


@include media($medium) {

	ul.posts {

		margin-left: 0;
		padding-left: 0;

		li {
			list-style-type: none;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
			line-height: 1.1rem;
			margin-bottom: 0.5em;
			margin-top: 0;
			border-top: none;

			>a {
				flex-shrink: 0;
				flex-grow: 1;
				margin-right: 1em;
				margin-top: 1.95rem;
				border-bottom: none;

				img {
					border-top: 1px dotted #d5d5f7;
					padding-right: 1rem;
					padding-top: 0.5rem;
				}

			}
			>div {
				flex-shrink: 1;
				flex-grow: 2;

				h2 {
					margin-bottom: 0.2rem;
				}

			}
		}
	}
}
