/* ==========================================================================
   Footnotes
   ========================================================================== */

.footnotes {
  font-family: $alt-font;
  p, li {
    @include font-size(12,no);
  }
  &:before {
    content: 'Footnotes:';
    font-weight: 700;
  }
}


/* ==========================================================================
   Aside
   ========================================================================== */

aside {

	h2 {
		font-size: 1.3em;
		margin-top: 3rem;
	}
	ul {
		padding-left: 1em;
		margin-bottom: 3rem;
		p {
			font-size: inherit;
			margin-top: 0;
		}
	}
	blockquote {
		position: relative;
		left: 2rem;
		border: none;
		padding-left: 0;
		text-indent: -1.4rem;
		margin-bottom: 1rem;

		div {
			font-style: italic;
			margin-top: 0.5em;
			text-align: right;

			&:before {
				content: "—";
				margin-right: 0.5em;
			}
		}
		&:before {
			content: '"';
			color: gray;
			font-size: 2em;
			position: relative;
			top: 0.25em;
			left: -0.8rem;	
			line-height: 1rem;
			font-style: italic;
			font-weight: bold;
		}
	}
	ul.related-by-tags {
		img {
			width: 100%;
			max-width: 20vh;
			display: block;
			float: left;
			clear: left;
			margin-right: 1rem;
		}
		li {
			overflow: hidden;
			position: relative;
			left: -1.5rem;
			padding-left: 1rem;

			&:before {
				content: "•";
				position: relative;
				left: -0.5rem;
				top: 0;
				font-size: 1.3em;
			}
		}
	}
}
