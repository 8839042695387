.row {
  margin-top: 0.5em;

  li {
    display: inline ;

    &::before {
      content: "—";
      margin: 0.5em;
    }
    &:first-child::before {
      content: "";
      margin: 0;
    }
  }
}
