/* ==========================================================================
   Buttons
   ========================================================================== */

/*
   Default button .btn
   ========================================================================== */

.btn {
	display: inline-block;
	padding: 8px 20px;
	@include font-size(14);
	font-family: $alt-font;
	font-weight: bold;
	background-color: $primary-color;
	color: $white;
	text-decoration: none;
	text-transform: uppercase;
	border: 0 !important;
	border-radius: 10 * $border-radius;
	@include transition(background 0.2s, border 0.2s);
	&:hover {
		color: $white;
		background-color: lighten($primary-color, 10);
	}
	&:active {
		// move button slightly on click
		@include transform(translateY(1px));
	}
}

/*
   Inverse button .btn-inverse
   ========================================================================== */

.btn-inverse {
	@extend .btn;
	background-color: $white;
	color: $text-color;
	&:visited,
	&:active {
		color: $text-color;
	}
	&:hover {
		color: $white;
		background-color: $text-color;
	}
}

/*
   Info button .btn-info
   ========================================================================== */

.btn-info {
	@extend .btn;
	background-color: $info-color;
	color: $white;
	&:visited {
		color: $white;
	}
	&:hover {
		background-color: lighten($info-color, 10);
	}
}

/*
   Warning button .btn-warning
   ========================================================================== */

.btn-warning {
	@extend .btn;
	background-color: $warning-color;
	color: $white;
	&:visited {
		color: $white;
	}
	&:hover {
		background-color: lighten($warning-color ,10);
	}
}

/*
   Success button .btn-success
   ========================================================================== */

.btn-success {
	@extend .btn;
	background-color: darken($success-color, 10);
	color: $white;
	&:visited {
		color: $white;
	}
	&:hover {
		background-color:lighten($success-color, 10);
	}
}

/*
   Danger button .btn-danger
   ========================================================================== */

.btn-danger {
	@extend .btn;
	background-color: $danger-color;
	color: $white;
	&:visited {
		color: $white;
	}
	&:hover {
		background-color: lighten($danger-color, 10);
	}
}

/*
   Social media buttons
   ========================================================================== */

.btn-social {
	$social:
    (facebook, $facebook-color),
		(flickr, $flickr-color),
		(foursquare, $foursquare-color),
		(google-plus, $google-plus-color),
		(instagram, $instagram-color),
		(linkedin, $linkedin-color),
		(pinterest, $pinterest-color),
		(rss, $rss-color),
		(tumblr, $tumblr-color),
		(twitter, $twitter-color),
		(vimeo, $vimeo-color),
		(youtube, $youtube-color);
	@extend .btn-inverse;
	color: $text-color !important;
	&:visited,
	&:active {
		color: $text-color;
	}
	border: 1px solid $border-color !important;
	@each $socialnetwork, $color in $social {
		i.fa-#{$socialnetwork} {
	    color: $color;
    }
	}
	&:hover {
		color: $white !important;
	}
	@each $socialnetwork, $color in $social {
		&.#{$socialnetwork}:hover {
	    background: $color;
	    border-color: $color;
	    @each $socialnetwork, $color in $social {
				i.fa-#{$socialnetwork} {
			    color: $white;
		    }
			}
    }
	}
}


/* animated button from https://codepen.io/kathykato/pen/rZRaNe */

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
  transition: $property $duration $ease;
}

$button-color: $link-color;

button {
  position: relative;
  display: inline-block;
  opacity: 0.75;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  margin: 1em 0;
  &.view, &.download {
    width: 14em;
    height: auto;
    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: relative;
      display: block;
      margin: 0;
      width: 2rem;
      height: 2rem;
      background: $button-color;
      border-radius: 2.625rem;
      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: white;
        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
          left: 0.125rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;
          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.2rem 0;
      margin: 0 0 0 1.85rem;
      color: $button-color;
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
      text-transform: uppercase;

		a {
			border: none;
		}
    }
  }
  &:hover {
    color: white;
    .circle {
      color: white;
      width: 100%;
      .icon {
        color: white;
        &.arrow {
          background: white;
          color: white;
          transform: translate(1rem, 0);
        }
      }
    }

    &.download {
      .circle {
        .icon {
          &.arrow {
              transform: translate(1rem, 0) rotate(90deg);
          }
        }
      }
    }
    .button-text {
		a {
      	color: white;
			border: none;
		}
		&:hover {
			color: white;
			border: none;
			border-color: $button-color;
		}
    }
  }
}

.page-content {
	p {
		a {
			&.button {
				border: none;
				&:hover {
					border: none;
					color: white;
				}
			}
			button {
				&:hover {
					color: white;
					.button-text {
						color: white;
					}
				}
			}
		}
	}
}
